import React,{ useState } from 'react';
import './Item.css';

const Item = ({ task, id, onDelete, onUpdate }) => {
    const handleUpdate = () => {
      const updatedTask = prompt('Введите новое название задачи:', task);
      if (updatedTask && updatedTask.trim() !== '') {
        onUpdate(id, updatedTask); 
      }
    };
  
    return (
      <div className="item">
        <div className="item__title">
          {task}
        </div>
        <button onClick={handleUpdate} className="item__content">
          Обновить
        </button>
        <button onClick={() => onDelete(id)} className="item__delete">
          Удалить
        </button>
      </div>
    );
  };

export default Item;