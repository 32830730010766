
import React , { useState, useEffect, createRef } from 'react';
import axios from 'axios';
import Order from '../Order/Order';

const Orders = () => {
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    axios.get('http://localhost:8000/api/data')
      .then((response) => {
        setOrders(response.data);
      })
      .catch((error) => {
        console.error('There was an error fetching the orders!', error);
      });
  }, []);

  return (
    <div>
      <h1>Заказы</h1>
      <div className="order-list">
        {orders.map((order) => (
           <Order
           key={order.id}
           name={order.name}
           order_date={order.order_date}
           price={order.price}
           description={order.description}
           address={order.address}
         />
        ))}
      </div>
    </div>
  );
};

export default Orders;
