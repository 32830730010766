import logo from './logo.svg';
import './App.css';
import React , { useState, useEffect, createRef } from 'react';
import axios from 'axios';
import Item from "./components/Item/Item";
import Header from './components/Header/Header';
import Nav from './components/Nav/Nav';
import Orders from './components/Orders/Orders';
import Auth from './components/Auth/Auth'; // Компонент авторизации/регистрации
import Register from './components/Register/Register'; // регистрации

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [showRegister, setShowRegister] = useState(false); // Состояние для переключения между регистрацией и авторизацией

 // Проверка токена в localStorage при загрузке страницы
 useEffect(() => {
  const token = localStorage.getItem('token');
  if (token) {
    setIsAuthenticated(true);
  }
}, []);

// Функция для выхода
const handleLogout = () => {
  localStorage.removeItem('token'); // Удаляем токен из localStorage
  setIsAuthenticated(false); // Устанавливаем состояние как неавторизованное
};

return (
  <div className="App">
    <Header /> {/* Header будет виден всегда */}

    {isAuthenticated ? (
      <>
        {/* Если пользователь авторизован, показываем Nav и Orders */}
        <Nav />
        
        <button onClick={handleLogout}>Logout</button> {/* Кнопка выхода */}
      </>
    ) : (
      <>
        {/* Если пользователь не авторизован, показываем компонент Auth или Register */}
        {showRegister ? (
          <Register setShowRegister={setShowRegister} /> // Страница регистрации
        ) : (
          <Auth setIsAuthenticated={setIsAuthenticated} setShowRegister={setShowRegister} /> // Страница авторизации
        )}
      </>
    )}
  </div>
);
}

export default App;
