import React, { useState }  from 'react';
import './Nav.css';
import CreateOrder from '../CreateOrder/CreateOrder';
import Orders from '../Orders/Orders';

const Nav = ({ username }) => {
  const [currentView, setCurrentView] = useState('orders'); // Управление отображаемым компонентом

  return (
    <div>
      <nav>
        <ul className="nav">
          <li>
            <a href="#" onClick={() => setCurrentView('orders')} className={currentView === 'orders' ? 'active' : ''}>
              Актуальные
            </a>
          </li>
          <li>
            <a href="#" onClick={() => setCurrentView('my-orders')} className={currentView === 'my-orders' ? 'active' : ''}>
              Мои поручения
            </a>
          </li>
          <li>
            <a href="#" onClick={() => setCurrentView('create')} className={currentView === 'create' ? 'active' : ''}>
              Создать поручение
            </a>
          </li>
        </ul>
      </nav>

      {/* Условный рендеринг содержимого в зависимости от currentView */}
      {currentView === 'orders' && <Orders />} {/* Показываем компонент Orders */}
      {currentView === 'my-orders' && <h2>Здесь будут мои поручения</h2>} {/* Можно заменить на компонент */}
      {currentView === 'create' && <CreateOrder />} {/* Показываем компонент CreateOrder */}
    </div>
  );
};


export default Nav;