import React, { useState } from "react";
import "./Order.css";

const Order = ({ name, order_date, price, description, address }) => {
  return (
    <div className="order-card">
      <div className="order-title">
        <h3 className="order-name">Заказчик: {name}</h3>
        <p className="order-date">{new Date(order_date).toLocaleString()}</p>
        <p className="order-address">Адрес: {address}</p>
      </div>
      <p className="order-description">Описание: <br/>
        {description}</p>
      <p className="order-price">{price} ₽</p>
    </div>
  );
};

export default Order;
