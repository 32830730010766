import React, { useState } from 'react';
import axios from 'axios';

const CreateOrder = ({ username }) => {
  const [description, setDescription] = useState('');
  const [address, setAddress] = useState('');
  const [price, setPrice] = useState('');

  // Функция для отправки данных на сервер
  const handleSubmit = async (e) => {
    e.preventDefault(); // Предотвращаем перезагрузку страницы

    try {
      // Отправляем данные заказа на сервер
      const response = await axios.post('http://localhost:8000/api/create-data', {
        description,
        address,
        price,
        username // Имя пользователя передаем с клиентской стороны
      });

      console.log('Поручение создано:', response.data);
      // Очистка полей после успешной отправки
      setDescription('');
      setAddress('');
      setPrice('');
    } catch (error) {
      console.error('Ошибка при создании поручения:', error);
    }
  };

  return (
    <div className="create-order">
      <h2>Создать поручение</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Описание:</label>
          <input
            type="text"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Адрес:</label>
          <input
            type="text"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Стоимость:</label>
          <input
            type="number"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            required
          />
        </div>
        <button type="submit">Создать заказ</button>
      </form>
    </div>
  );
};

export default CreateOrder;
