import React, { useState } from 'react';
import axios from 'axios';

const Register = ({ setShowRegister }) => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  // Функция для регистрации
  const handleRegister = async () => {
    try {
      await axios.post('http://localhost:8000/auth/register', { username, email, password });
      alert('Регистрация успешна! Пожалуйста, войдите в систему.');
      setShowRegister(false); // Переключаемся на страницу авторизации после регистрации
    } catch (error) {
      console.error('Registration error', error);
      alert('Ошибка при регистрации');
    }
  };

  return (
    <div>
      <h2>Регистрация</h2>
      <input
        type="text"
        placeholder="Имя"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
      />
      <input
        type="email"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <input
        type="password"
        placeholder="Пароль"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <button onClick={handleRegister}>Зарегистрироваться</button>

      <p>Уже есть аккаунт? <button onClick={() => setShowRegister(false)}>Войти</button></p>
    </div>
  );
};

export default Register;
