import React, { useState } from 'react';
import axios from 'axios';

const Auth = ({ setIsAuthenticated , showRegister, setShowRegister}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  // Функция для входа
  const handleLogin = async () => {
    try {
      const response = await axios.post('http://localhost:8000/auth/login', { email, password });
      const token = response.data.token;
      localStorage.setItem('token', token); // Сохраняем токен в localStorage
      setIsAuthenticated(true); // Устанавливаем пользователя как авторизованного
    } catch (error) {
      console.error('Login error', error);
    }
  };

  return (
    <div>
      <h2>Login</h2>
      <input
        type="email"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <button onClick={handleLogin}>Login</button>

      <p>Нет аккаунта? <button onClick={() => setShowRegister(true)}>Регистрация</button></p>
    </div>
  );
};
export default Auth;
